import _objectSpread from "/Volumes/workspace/project/twiboss/frontend/twi-super-admin/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.array.slice.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { getApiModuleList, editApiModule, deleteApiModule } from '@/api/apiModule';
import { getApiGroupList } from '@/api/apiGroup';
import { getSubscriptionServerList } from '@/api/sub_service';
export default {
  name: "ManagerApiModules",
  data: function data() {
    return {
      data: [],
      queryParams: {},
      loading: false,
      page: 1,
      total: 0,
      serveOptions: [],
      formLabelWidth: '120px',
      submitting: false,
      dialogNewVisible: false,
      editIndex: '',
      newForm: {
        id: 0,
        name: '',
        api_ids: [],
        serve_ids: []
      },
      rules: {
        name: [{
          required: true,
          message: '权限包名称 必填'
        }],
        api_ids: [{
          required: true,
          message: '接口 必填'
        }]
      },
      apiList: [],
      apiLoading: false
    };
  },
  created: function created() {
    var _this = this;

    this.requestData(1);
    getSubscriptionServerList({
      group: 2,
      page: 1,
      pageSize: 500
    }).then(function (res) {
      _this.serveOptions = res.data.list;
    });
  },
  methods: {
    requestData: function requestData(page) {
      var _this2 = this;

      if (page !== null) {
        this.page = page;
      }

      this.loading = true;
      getApiModuleList(_objectSpread(_objectSpread({}, this.queryParams), {}, {
        page: this.page
      })).then(function (res) {
        _this2.loading = false;
        _this2.data = res.data.data;
        _this2.total = res.data.total_count;
      }).catch(function (e) {
        _this2.$message.error(e.message);
      });
    },
    handleDelete: function handleDelete(index, row) {
      var _this3 = this;

      this.$confirm('您确定要删除这项数据吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        deleteApiModule({
          id: row.id
        }).then(function () {
          _this3.$message.success('删除成功');

          _this3.data.splice(index, 1);
        }).catch(function (e) {
          _this3.$message.error(e.message);
        });
      }).catch(function () {
        _this3.$message.info('已取消删除');
      });
    },
    onDialogClose: function onDialogClose() {
      this.newForm = {
        id: 0,
        name: '',
        api_ids: [],
        serve_ids: []
      };
    },
    handleEdit: function handleEdit(index, row) {
      var _this4 = this;

      this.onDialogClose();
      this.editIndex = '';

      if (row) {
        Object.keys(row).forEach(function (key) {
          if (Object.prototype.hasOwnProperty.call(_this4.newForm, key) && row[key] != null) _this4.newForm[key] = row[key];
        });
        this.editIndex = index;
      }

      this.dialogNewVisible = true;
      this.apiLoading = true;
      getApiGroupList({
        module_id: this.newForm.id
      }).then(function (res) {
        _this4.apiList = res.data.data;

        _this4.$refs.tree.setCheckedKeys(_this4.setCheckedKeys(res.data.module_api));

        if (res.data.module_has_serve) {
          _this4.newForm.serve_ids = res.data.module_has_serve;
        }

        _this4.apiLoading = false;
      });
    },
    setCheckedKeys: function setCheckedKeys(arrayIds) {
      var ids = [];

      if (arrayIds && arrayIds.length > 0) {
        for (var i = 0; i < arrayIds.length; i++) {
          ids.push('2_' + arrayIds[i]);
        }
      }

      return ids;
    },
    getCheckedKeys: function getCheckedKeys() {
      var ids = this.$refs.tree.getCheckedKeys();
      var arr = [];

      if (ids.length > 0) {
        for (var i = 0; i < ids.length; i++) {
          arr = ids[i].split('_');

          if (arr[0] == 2) {
            this.newForm.api_ids.push(parseInt(arr[1]));
          }
        }
      }
    },
    handleSave: function handleSave(formName) {
      var _this5 = this;

      this.getCheckedKeys();
      this.submitting = true;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var form = _objectSpread({}, _this5.newForm);

          editApiModule(form).then(function (res) {
            _this5.submitting = false;

            _this5.$message.success('编辑成功');

            _this5.dialogNewVisible = false;

            if (_this5.editIndex === "") {
              _this5.data.unshift(res.data);
            } else {
              var arr = _this5.data.slice();

              arr[_this5.editIndex] = res.data;
              _this5.data = arr;
            }
          }).catch(function (e) {
            _this5.submitting = false;

            _this5.$message.error(e.message);
          });
        } else {
          _this5.submitting = false;
        }
      });
    }
  }
};