import service from '@/utils/request'; // @Tags SubscriptionServer
// @Summary 创建SubscriptionServer
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.SubscriptionServer true "创建SubscriptionServer"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /subscriptionServer/createSubscriptionServer [post]

export var createSubscriptionServer = function createSubscriptionServer(data) {
  return service({
    url: "/subscriptionServer/createSubscriptionServer",
    method: 'post',
    data: data
  });
}; // @Tags SubscriptionServer
// @Summary 删除SubscriptionServer
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.SubscriptionServer true "删除SubscriptionServer"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /subscriptionServer/deleteSubscriptionServer [delete]

export var deleteSubscriptionServer = function deleteSubscriptionServer(data) {
  return service({
    url: "/subscriptionServer/deleteSubscriptionServer",
    method: 'delete',
    data: data
  });
}; // @Tags SubscriptionServer
// @Summary 删除SubscriptionServer
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.IdsReq true "批量删除SubscriptionServer"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /subscriptionServer/deleteSubscriptionServer [delete]

export var deleteSubscriptionServerByIds = function deleteSubscriptionServerByIds(data) {
  return service({
    url: "/subscriptionServer/deleteSubscriptionServerByIds",
    method: 'delete',
    data: data
  });
}; // @Tags SubscriptionServer
// @Summary 更新SubscriptionServer
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.SubscriptionServer true "更新SubscriptionServer"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /subscriptionServer/updateSubscriptionServer [put]

export var updateSubscriptionServer = function updateSubscriptionServer(data) {
  return service({
    url: "/subscriptionServer/updateSubscriptionServer",
    method: 'put',
    data: data
  });
}; // @Tags SubscriptionServer
// @Summary 用id查询SubscriptionServer
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.SubscriptionServer true "用id查询SubscriptionServer"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /subscriptionServer/findSubscriptionServer [get]

export var findSubscriptionServer = function findSubscriptionServer(params) {
  return service({
    url: "/subscriptionServer/findSubscriptionServer",
    method: 'get',
    params: params
  });
}; // @Tags SubscriptionServer
// @Summary 分页获取SubscriptionServer列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "分页获取SubscriptionServer列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /subscriptionServer/getSubscriptionServerList [get]

export var getSubscriptionServerList = function getSubscriptionServerList(params) {
  return service({
    url: "/subscriptionServer/getSubscriptionServerList",
    method: 'get',
    params: params
  });
}; // @Tags SubscriptionServers
// @Summary 获取指定用户的订购记录
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body userId true "获取指定用户的订购记录"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /subscriptionServers/getUserSubscription [get]

export var getUserSubscription = function getUserSubscription(params) {
  return service({
    url: "/subscriptionServer/getUserSubscription",
    method: 'get',
    params: params
  });
};
export var addTimes = function addTimes(data) {
  return service({
    url: "/subscriptionServer/addTimes",
    method: 'post',
    data: data
  });
};