import service from '@/utils/request';
var host = process.env.NODE_ENV === 'production' ? 'http://v3.twiboss.com' : '';
export var getApiGroupList = function getApiGroupList(params) {
  return service({
    url: host + "/admin/openApi/getApiGroupList",
    method: 'get',
    params: params
  });
};
export var editApiGroup = function editApiGroup(data) {
  return service({
    url: host + "/admin/openApi/getApiGroupList",
    method: 'post',
    data: data
  });
};
export var deleteApiGroup = function deleteApiGroup(data) {
  return service({
    url: host + "/admin/openApi/getApiGroupList",
    method: 'delete',
    data: data
  });
};