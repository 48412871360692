var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "search-term" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: { inline: true, model: _vm.queryParams }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "关键字搜索：" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.queryParams.keyword,
                      callback: function($$v) {
                        _vm.$set(_vm.queryParams, "keyword", $$v)
                      },
                      expression: "queryParams.keyword"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.requestData(1)
                        }
                      }
                    },
                    [_vm._v("查询")]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.handleEdit(-100, null)
                        }
                      }
                    },
                    [_vm._v("添加权限包")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          attrs: { data: _vm.data, border: "", stripe: "" }
        },
        [
          _c("el-table-column", { attrs: { prop: "id", label: "ID" } }),
          _c("el-table-column", { attrs: { prop: "name", label: "名称" } }),
          _c("el-table-column", {
            attrs: { label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "medium" },
                        on: {
                          click: function($event) {
                            return _vm.handleEdit(scope.$index, scope.row)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    ),
                    _c("el-divider", { attrs: { direction: "vertical" } }),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "medium" },
                        on: {
                          click: function($event) {
                            return _vm.handleDelete(scope.$index, scope.row)
                          }
                        }
                      },
                      [_vm._v("删除")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        style: { float: "right", padding: "20px" },
        attrs: {
          "current-page": _vm.page,
          "page-size": 20,
          layout: "total, prev, pager, next",
          total: _vm.total
        },
        on: {
          "current-change": function($event) {
            return _vm.requestData(null)
          },
          "update:currentPage": function($event) {
            _vm.page = $event
          },
          "update:current-page": function($event) {
            _vm.page = $event
          }
        }
      }),
      _c(
        "el-drawer",
        {
          ref: "drawer",
          attrs: {
            "destroy-on-close": "",
            title: "权限包增加/修改",
            visible: _vm.dialogNewVisible,
            direction: "rtl",
            size: "50%",
            withHeader: false,
            "custom-class": "demo-drawer"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogNewVisible = $event
            },
            close: _vm.onDialogClose
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "demo-drawer__content",
              staticStyle: { "margin-top": "15px" }
            },
            [
              _c(
                "el-form",
                {
                  ref: "newForm",
                  attrs: {
                    model: _vm.newForm,
                    rules: _vm.rules,
                    "label-width": _vm.formLabelWidth
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "权限包名称", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { autofocus: true },
                        model: {
                          value: _vm.newForm.name,
                          callback: function($$v) {
                            _vm.$set(_vm.newForm, "name", $$v)
                          },
                          expression: "newForm.name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "选择订购产品", prop: "serve_ids" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { multiple: "", placeholder: "请选择" },
                          model: {
                            value: _vm.newForm.serve_ids,
                            callback: function($$v) {
                              _vm.$set(_vm.newForm, "serve_ids", $$v)
                            },
                            expression: "newForm.serve_ids"
                          }
                        },
                        _vm._l(_vm.serveOptions, function(item) {
                          return _c("el-option", {
                            key: item.ID,
                            attrs: { label: item.title, value: item.ID }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "选择接口", prop: "api_ids" } },
                    [
                      _c(
                        "el-card",
                        { attrs: { shadow: "never" } },
                        [
                          _c("el-tree", {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.apiLoading,
                                expression: "apiLoading"
                              }
                            ],
                            ref: "tree",
                            attrs: {
                              data: _vm.apiList,
                              props: { children: "children", label: "name" },
                              "show-checkbox": "",
                              "default-expand-all": "",
                              "node-key": "type_id",
                              "highlight-current": ""
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("el-form-item", [
                    _c(
                      "div",
                      { staticClass: "demo-drawer__footer" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", loading: _vm.submitting },
                            on: {
                              click: function($event) {
                                return _vm.handleSave("newForm")
                              }
                            }
                          },
                          [_vm._v("确定")]
                        ),
                        _c(
                          "el-button",
                          {
                            on: {
                              click: function($event) {
                                _vm.dialogNewVisible = false
                              }
                            }
                          },
                          [_vm._v("取 消")]
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }