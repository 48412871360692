import service from '@/utils/request';
var host = process.env.NODE_ENV === 'production' ? 'http://v3.twiboss.com' : '';
export var getApiModuleList = function getApiModuleList(params) {
  return service({
    url: host + "/admin/openApi/getApiModuleList",
    method: 'get',
    params: params
  });
};
export var editApiModule = function editApiModule(data) {
  return service({
    url: host + "/admin/openApi/getApiModuleList",
    method: 'post',
    data: data
  });
};
export var deleteApiModule = function deleteApiModule(data) {
  return service({
    url: host + "/admin/openApi/getApiModuleList",
    method: 'delete',
    data: data
  });
};